import React from "react";

export const TermsAndConditions = () => {
  return (
    <div className="body">
      <div>
        <div className="h1">Terms and Conditions</div>
        <p>
          Welcome to GameTeam! By using our mobile application ("GameTeam"),
          owned and operated by Coding Hands Infotech LLP, you agree to comply
          with and be bound by the following terms and conditions ("Terms").
          Please read them carefully before using the app.
        </p>
        <div className="h2">1. Acceptance of Terms</div>
        <p>
          By downloading, accessing, or using GameTeam, you agree to be bound by
          these Terms. If you do not agree to these Terms, you must not use the
          app.
        </p>
        <div className="h2">2. Use of the App</div>
        <p>
          You agree to use the GameTeam app for lawful purposes and in a way
          that does not infringe the rights of others or restrict their use and
          enjoyment of the app. You may not use the app for any of the following
          purposes:
          <ul class="custom-image-bullets">
            <li>Engaging in any unlawful, illegal, or fraudulent activity</li>
            <li>Posting harmful, offensive, or inappropriate content</li>
            <li>Interfering with the proper functioning of the app</li>
          </ul>
        </p>
        <div className="h2">3. Account Registration</div>
        <p>
          To use the full features of GameTeam, you must create an account using
          either Google or Apple Sign-In. You agree to provide accurate and
          complete information during the registration process and keep your
          account information up to date. You are responsible for safeguarding
          your account and ensuring that no unauthorized access occurs.
        </p>
        <div className="h2">4. User Conduct</div>
        <p>
          As a user of GameTeam, you agree:
          <ul class="custom-image-bullets">
            <li>Not to harass, abuse, or harm other users of the app</li>
            <li>
              To respect the decisions of activity organizers regarding session
              details and participation
            </li>
            <li>
              To be punctual and courteous when attending sports sessions
              organized through GameTeam
            </li>
            <li>Not to impersonate another person or entity</li>
          </ul>
        </p>
        <div className="h2">5. Content</div>
        <p>
          You are solely responsible for the content you post within the app,
          including session descriptions, messages, and profile information. By
          posting content, you grant GameTeam the right to use, display, and
          share that content within the app for the purposes of delivering our
          services.
        </p>
        <div className="h2">6. Session Organizers</div>
        <p>
          If you are an activity organizer, you are responsible for creating
          accurate and detailed session descriptions, managing the participant
          list, and updating session information as needed. You agree to provide
          a safe and respectful environment for all participants.
        </p>
        <div className="h2">7. Termination of Use</div>
        <p>
          We reserve the right to suspend or terminate your access to GameTeam
          at any time, without notice, for any violation of these Terms or any
          other reason that we deem necessary to protect the app and its users.
        </p>
        <div className="h2">8. Limitation of Liability</div>
        <p>
          Coding Hands Infotech LLP will not be held liable for any direct,
          indirect, incidental, or consequential damages arising out of your use
          of GameTeam. We provide the app "as is" without any warranties of any
          kind, express or implied.
        </p>
        <div className="h2">9. Indemnification</div>
        <p>
          You agree to indemnify and hold harmless Coding Hands Infotech LLP,
          its affiliates, officers, directors, employees, and agents from any
          claims, damages, losses, or expenses arising out of your use of
          GameTeam, your violation of these Terms, or your violation of any
          third-party rights.
        </p>
        <div className="h2">10. Changes to the Terms</div>
        <p>
          We may modify or update these Terms at any time. Your continued use of
          GameTeam after any changes have been made constitutes your acceptance
          of the revised Terms. We encourage you to review this page regularly
          for updates.
        </p>
        <div className="h2">11. Governing Law</div>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of India. Any disputes arising under or in connection with these Terms
          will be subject to the exclusive jurisdiction of the courts located in
          Kerala, India.
        </p>
        <div className="h2">12. Contact Us</div>
        <p>
          If you have any questions or concerns about these Terms, please
          contact us at{" "}
          <a href="mailto:gameteam@codinghands.in">gameteam@codinghands.in</a>.
        </p>
        <div>
          <b>Effective Date:</b> [Insert Date]
        </div>
      </div>
    </div>
  );
};
