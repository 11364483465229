import React from "react";

export const Privacy = () => {
  return (
    <div className="body">
      <div>
        <div className="h1">Privacy Policy</div>
        <p>
          At GameTeam, we are committed to protecting your privacy. This Privacy
          Policy explains how we collect, use, and protect your personal
          information when you use our mobile application ("GameTeam"). GameTeam
          is owned and operated by <b>Coding Hands Infotech LLP</b>. By using
          GameTeam, you agree to the collection and use of your information in
          accordance with this policy.
        </p>
        <div className="h2">1. Information We Collect</div>
        <div className="question">1.1 Personal Information:</div>
        <p>
          When you sign up for GameTeam, we collect certain personally
          identifiable information that can be used to identify or contact you.
          This information includes:
          <ul class="custom-image-bullets">
            <li>First item</li>
            <li>Second item</li>
            <li>Third item</li>
          </ul>
          We collect this information when you register using Google or Apple
          Sign-In.
        </p>
        <div className="question">1.2 Usage Data:</div>
        <p>
          We may also collect information on how the app is accessed and used,
          including your IP address, browser type, and device information (such
          as device type and operating system).
        </p>
        <div className="h2">2. How We Use Your Information</div>
        <div className="question">We use your personal information to:</div>
        <p>
          <ul class="custom-image-bullets">
            <li>Create and manage your account on GameTeam</li>
            <li>Enable you to create and join sports sessions</li>
            <li>Communicate with other users within the app</li>
            <li>Provide customer support and address any issues</li>
            <li>Improve the app’s performance and user experience</li>
          </ul>
        </p>
        <div className="h2">3. Sharing Your Information</div>
        <div className="question">1. How do I find and join a session?</div>
        <p>
          <div>You can search for sessions by sport, location, and date:</div>
          <ul class="custom-image-bullets">
            <li>
              With other users of the app, as needed to facilitate your
              participation in sessions
            </li>
            <li>If required by law or to respond to a legal process</li>
            <li>
              To protect the rights, property, and safety of Coding Hands
              Infotech LLP, our users, or the public
            </li>
          </ul>
        </p>
        <div className="h2">4. Data Security</div>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access or disclosure. However, please note that no method
          of transmission over the internet or electronic storage is 100%
          secure.
        </p>
        <div className="h2">5. Your Data Rights</div>
        <p>
          You have the right to:
          <ul class="custom-image-bullets">
            <li>
              Access, update, or delete the personal information we have about
              you
            </li>
            <li>
              Withdraw your consent for us to collect and process your
              information at any time
            </li>
          </ul>
          If you would like to exercise these rights, please contact us at{" "}
          <a href="mailto:gameteam@codinghands.in"> gameteam@codinghands.in</a>{" "}
          .
        </p>
        <div className="h2">6. Changes to This Privacy Policy</div>
        <p>
          We may update our Privacy Policy from time to time. Any changes will
          be posted on this page, and we encourage you to review it
          periodically. Your continued use of GameTeam after any updates
          signifies your acceptance of the changes.
        </p>
        <div className="h2">7. Contact Us</div>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at{" "}
          <a href="mailto:gameteam@codinghands.in">gameteam@codinghands.in</a>.
        </p>
        <b>Effective Date:</b> 01-10-2024
      </div>
    </div>
  );
};
