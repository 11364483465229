import React from "react";
import iOSBox from "../assets/img/iOS.svg";
import AndroidOS from "../assets/img/Android OS.svg";
import screenOne from "../assets/img/Screen shot1.svg";
import screenTwo from "../assets/img/screen_shot-2.svg";
import DownloadText from "../assets/img/Download Text.svg";
import activityscreen5 from "../assets/img/activity-screen-5.svg";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import ContentBlock from "./ContentBlock";

export const ActivityPage = ({ componentRef }) => {
  return (
    <Stack justifyContent="center">
      <div className="activity-page-one">
        <Grid container className="activity-page-one-grid">
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <ContentBlock
              title={{ first: "Find activities", second: "near you" }}
              contentBlock={[
                "Find nearby activities and join them. Remember, you can also tag your friends on the go.",
                "Didn’t find one suitable, create yours within seconds",
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="right-block">
              <div className="right-wrapper">
                <img src={screenOne} alt="" className="screen-one" />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        <div className="activity-page-two">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} className="left-block">
              <div className="img-container">
                <img src={screenTwo} alt="" className="screenTwo" />
              </div>
              <img src={screenTwo} alt="" className="screenTwo-phone-size" />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"center"}
            >
              <ContentBlock
                alignment="right-block"
                title={{
                  first: "Create new activities",
                  second: "within seconds",
                }}
                contentBlock={[
                  "Find nearby activities and join them. Remember, you can also",
                  "Didn’t find one suitable, create yours within seconds",
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Stack pt={25} pb={10} width={"100%"} justifyContent={"center"}>
        <div className="activity-page-section">
          <div className="content-wrapper">
            <span className="Privacy-Policy">
              Stay connected with
              <span className="Game-Team"> Game Team.</span> Get instant updates
              on activities around you. Or create a new one.!
            </span>
            <span className="terms-section">
              By installing Game Team, you agree to our Terms & Privacy Policy.
            </span>
          </div>
          <div ref={componentRef}>
            <div className="content-block-3">
              <div className="content-section">
                <img
                  src={DownloadText}
                  alt="image"
                  className="DownloadText-img"
                />
                <div className="os-img">
                  <Link to="/coming-soon" style={{ marginRight: "10px" }}>
                    <img src={AndroidOS} alt="image" />
                  </Link>
                  <Link to="/coming-soon" style={{ marginRight: "10px" }}>
                    <img src={iOSBox} alt="image" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack>

      <Stack
        width={"100%"}
        justifyContent={"center"}
        className="activity-page-5"
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <ContentBlock
              title={{
                first: "Start groups and create",
                second: "private activities",
              }}
              contentBlock={[
                "Find nearby activities and join them. Remember, you can also tag your friends on the go.",
                "Didn’t find one suitable, create yours within seconds.",
              ]}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            className="mob-screen-layout"
          >
            <img src={activityscreen5} alt="" />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
