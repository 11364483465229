import { Route, Routes } from "react-router-dom";
import {
  Banner,
  HeaderSection,
  ActivityPage,
  Footer,
  Faq,
  Privacy,
  TermsAndConditions,
  AndroidOs,
  DeleteAccountPage,
  Iosbox,
  GooglePlayPage,
  AppStore,
} from "./components";
import { Container } from "@mui/material";
import { useRef } from "react";
import { ComingSoon } from "./components/ComingSoon";

function App() {
  const componentRef = useRef(null);

  const scrollToComponent = () => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Routes>
      <Route
        element={
          <>
            <Container
              maxWidth={"xl"}
              className="container"
              sx={{ pb: "75px" }}
            >
              <header className="header">
                <HeaderSection scrollToComponent={scrollToComponent} />
              </header>
              <Banner />
              <ActivityPage componentRef={componentRef} />
              <Footer />
            </Container>
          </>
        }
        path={"/"}
      />
      <Route element={<Faq />} path={"/faq"} />
      <Route element={<Privacy />} path={"/privacy-policy"} />
      <Route element={<TermsAndConditions />} path={"/terms-and-conditions"} />
      <Route element={<ComingSoon />} path={"/coming-soon"} />
      <Route element={<DeleteAccountPage />} path={"/delete-account"} />
    </Routes>
  );
}

export default App;
