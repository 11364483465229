import { Box, Stack } from "@mui/material";
import AppStore from "../assets/img/App-Store.svg";
import GooglePlay from "../assets/img/Google-Play.svg";
import Logo from "../assets/img/GameTeamLuanch3.svg";
import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <Stack direction={"row"} justifyContent={"center"}>
      <Stack className="footer" justifyContent={"space-between"}>
        <div className="footer-section">
          <div className="footer-block">
            <img className="logo" src={Logo} />
            <span className="game2">GAME</span>
            <span className="team2">TEAM</span>
          </div>
          <div className="footer-block-2">
            <Stack spacing={1}>
              <Box>
                <Link to="/coming-soon">
                  <img src={GooglePlay} alt="Google Play Store" />
                </Link>
                <Link to="/coming-soon">
                  <img src={AppStore} alt="Apple App Store" />
                </Link>
              </Box>
              <Stack direction="row" gap={2}>
                <div className="custom-text">Contact {""}:</div>
                <Link
                  to="mailto:gameteam@codinghands.in"
                  className="footer-link"
                >
                  gameteam@codinghands.in
                </Link>
              </Stack>
            </Stack>
          </div>
        </div>

        <Stack flex="direction" direction="row" justifyContent="space-between">
          <Stack spacing={3} direction="row">
            <Link to="/terms-and-conditions" className="footer-link">
              Terms & Conditions
            </Link>
            <Link to="/privacy-policy" className="footer-link">
              Privacy Policy
            </Link>
            <Link to="/faq" className="footer-link">
              FAQ
            </Link>
          </Stack>
          <Stack>
            <Link to="https://codinghands.in/" className="footer-link">
              Copyright © 2024 - Coding Hands Infotech LLP
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
