import { Stack, Typography } from "@mui/material";
import React from "react";

export default function ContentBlock({
  title,
  contentBlock,
  alignment = "left-block",
}) {
  return (
    <Stack className={alignment} gap="20px">
      <Stack>
        <Typography variant="h1" fontSize="36px" fontWeight={700}>
          {title.first}
        </Typography>
        <Typography ml="70px" variant="h1" fontSize="36px" fontWeight={700}>
          {title.second}
        </Typography>
      </Stack>
      <div className="discription-block">
        {contentBlock.map((item, index) => (
          <Typography
            pt={index !== 0 ? "16px" : "none"}
            fontSize="16px"
            fontWeight={400}
          >
            {item}
          </Typography>
        ))}
      </div>
    </Stack>
  );
}
