import React from "react";
import DeleteAppImage from "../assets/img/dlt-app-img.jpeg";

export const DeleteAccountPage = () => {
  return (
    <div className="body">
      <div>
        <div className="h1">How to Delete Your Account</div>

        <p>
          <div>
            1.<b>Sign in to the app </b>using your credentials.
          </div>
          <div>
            2.Navigate to your <b>Profile</b> by selecting it from the bottom
            navigation bar.
          </div>
          <div>
            3.Select the <b>Delete Account </b>option.
          </div>
          <div>
            4.A confirmation window will pop up, asking if you want to proceed.
            To permanently delete your account, click <b>Delete</b>.
          </div>
        </p>
        <img
          src={DeleteAppImage}
          alt="Google Play Store"
          className="img-custom-size"
        />
      </div>
    </div>
  );
};
