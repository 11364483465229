import React from "react";

export const Faq = () => {
  return (
    <div className="body">
      <div>
        <div className="h1">WHY DO WE COLLECT INFORMATION FROM YOU?</div>
        <div className="h2">General</div>
        <div className="question">1. What is GameTeam?</div>
        <p>
          GameTeam is a platform where sports players can create and join group
          sessions for various sports activities. Whether you're an organizer or
          a participant, the platform helps you connect with others to play or
          practice sports at any location.
        </p>
        <div className="question">2. Is GameTeam free to use?</div>
        <p>
          Yes, GameTeam is completely free to download and use. However, some
          sessions may have costs, as determined by the session organizers.
        </p>
        <div className="question">
          3. What types of sports can I organize or join?
        </div>
        <p>
          GameTeam supports all types of sports, including football, basketball,
          tennis, cricket, badminton, and more. If the sport isn’t listed, you
          can add it when creating a session.
        </p>
        <div className="question">4. Can I use GameTeam in my location?</div>
        <p>
          GameTeam is designed for global use. As long as you have players or
          organizers near your location, you can create or join sessions
          anywhere.
        </p>
        <div className="question">5. Can I use the app without signing up?</div>
        <p>
          No, you need to sign up using Google or Apple Sign-In to create or
          join sessions.
        </p>

        <div className="h2">For Activity Organizers</div>

        <div className="question">1. How do I create a session?</div>
        <p>
          <div>To create a session, follow these steps:</div>
          <div>- Open the app and tap "Create Session."</div>
          <div>
            - Enter details like game type, time, location, number of players
            required, cost, and other details.
          </div>
          <div>- Share the session publicly or with specific groups.</div>
        </p>
        <div className="question">
          2. Can I update my session after it’s created?
        </div>
        <p>
          Yes, you can easily edit the session details at any time before the
          session begins. Just go to the session details page, tap "Edit," and
          update the information.
        </p>
        <div className="question">
          3. How do I invite specific players to my session?
        </div>
        <p>
          After creating a session, you can share the session link with
          individual players or specific groups via the app’s built-in sharing
          features.
        </p>
        <div className="question">
          4. What happens if not enough players join my session?
        </div>
        <p>
          If the number of participants required is not met, you can either keep
          the session open for last-minute joiners or cancel it. Players will be
          notified of any changes.
        </p>
        <div className="question">5. Can I charge a fee for my session?</div>
        <p>
          Yes, you can set a cost for your session when creating it. Be sure to
          include any payment details in the session description.
        </p>

        <div className="h2">For Players</div>
        <div className="question">1. How do I find and join a session?</div>
        <p>
          <div>You can search for sessions by sport, location, and date:</div>
          <div>
            - Open the app and use the search bar to look for nearby sessions.
          </div>
          <div>
            - Filter results based on date, location, or specific sports.
          </div>
          <div>
            - When you find a suitable session, simply tap "Join" to
            participate.
          </div>
        </p>
        <div className="question">
          2. Can I communicate with the session organizer?
        </div>
        <p>
          Yes, once you join a session, you can message the organizer directly
          within the app to ask questions or confirm details.
        </p>
        <div className="question">
          3. How do I know if a session is suitable for my skill level?
        </div>
        <p>
          There’s no specific skill level setting at the moment. However, you
          can communicate with the organizer through the in-app messaging option
          to ask about the session's level of play.
        </p>
        <div className="question">
          4. What if I can’t attend a session after joining?
        </div>
        <p>
          If you need to withdraw from a session, simply go to the session page
          and tap "Withdraw." It’s polite to notify the organizer as soon as
          possible if you can’t make it.
        </p>
        <div className="question">5. Can I join private group sessions?</div>
        <p>
          Yes, but you need to be part of the group that the session was shared
          with. If you’re not a member of that group, you can request an invite
          from the session organizer.
        </p>

        <div className="h2">Account and Technical Questions</div>

        <div className="question">
          1. How do I create an account on GameTeam?
        </div>
        <p>
          Creating an account is simple: - Download the GameTeam app from the
          App Store or Google Play. - Sign up using your Google or Apple
          account. - Complete your profile by adding basic information like your
          name and preferred sports.
        </p>
        <div className="question">
          2. How do I report an issue with the app?
        </div>
        <p>
          If you encounter any problems, please send an email to{" "}
          <a href="mailto:gameteam@codinghands.in">gameteam@codinghands.in</a>{" "}
          with the details of the issue, and our support team will assist you.
        </p>
        <div className="question">3. How can I delete my account?</div>
        <p>
          If you wish to delete your account, go to your profile settings,
          scroll down to "Delete Account," and follow the prompts. Keep in mind
          that this action is irreversible.
        </p>
      </div>
    </div>
  );
};
