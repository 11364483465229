import React from "react";
import { Stack } from "@mui/material";
import Logo from "../assets/img/GameTeamLuanch3.svg";

export const HeaderSection = ({ scrollToComponent }) => {
  return (
    <Stack className="header-section">
      <Stack
        direction={"flex"}
        className="searchBar"
        justifyContent={"space-between"}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          gap={2}
          className="left-section"
        >
          <img src={Logo} className="logo-dummy" />
          <span className="game2">GAME</span>
          <span className="game2">TEAM</span>
        </Stack>

        <Stack>
          <button className="nav-menu-btn" onClick={scrollToComponent}>
            Download App
          </button>
        </Stack>
      </Stack>
    </Stack>
  );
};
